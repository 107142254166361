.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  margin: 20px 0 30px 0;
}

.loader {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #3f45ad;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  animation: spin 1s linear infinite;
}

.loading_caption{
    color: #888;
    font-size: 16px;
    margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
