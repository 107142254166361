.wrap-error {
  background-color: #3f45ad;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23f0b608' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23e6d710' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23e7af05' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23e7d808' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23d8a408' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23f1e213' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23f0b607' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23e4d506' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23eab822' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23e8da14' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23e8b008' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23edde14' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  height: calc(var(--vh, 1vh) * 100);
}
.wrap-error h1 {
  font-size: 7rem;
  letter-spacing: -13px;
  line-height: 1;
  font-family: montserrat, sans-serif;
}
h1 span {
  text-shadow: -8px 0 0 rgb(63, 69, 173);
  
}
.text-9xl {
  font-size: 20px;
}

.wrap-error h5{
  font-size: 22px;
}
.wrap-error p{
  font-size: 17px;
}

.btn-dark{
  background-color: #646ae0;
  border: none !important;
  font-size: 17px;
}
.btn-dark:hover{
  background-color: #343994;
}